<template>
    <el-form ref="form" :model="form" :rules="rules">
        <div class="grid grid-cols-12 gap-4 px-5 relative">
            <div class="col-span-3 px-2">
                <el-form-item class="w-full" prop="status">
                    <label class="text-gray-400 mb-0 block">帳號狀態</label>
                    <el-select v-model="form.status" class="w-full" placeholder="請選擇狀態">
                        <el-option v-for="(option, index) in statusOptions" :key="index" :label="option.label" :value="option.value"> </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="col-span-9 px-2">
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="login">
                        <label class="text-gray-400 mb-0 leading-none">
                            登入帳號
                            <el-input v-model="form.login" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="password">
                        <label class="text-gray-400 mb-0 leading-none">
                            密碼
                            <div class="relative">
                                <el-input v-model="form.password" :type="!showPassword ? 'password' : 'text'" class="w-full"></el-input>
                                <!-- 眼睛開關 用來顯示密碼使用 -->
                                <span class="absolute right-2 top-1 cursor-pointer text-xl" @click="showPassword = !showPassword">
                                    <i v-if="showPassword" class="icofont-eye-alt"></i>
                                    <i v-if="!showPassword" class="icofont-eye-blocked"></i>
                                </span>
                            </div>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="name">
                        <label class="text-gray-400 mb-0 leading-none">
                            介紹人 / 公司名稱
                            <el-input v-model="form.name" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                    <el-form-item class="w-full md:col-span-1 col-span-2 mb-3" prop="representative">
                        <label class="text-gray-400 mb-0 leading-none">
                            真實姓名 / 代表人
                            <el-input v-model="form.representative" class="w-full"></el-input>
                        </label>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full md:col-span-1 col-span-2 mb-3">
                        <el-form-item prop="email">
                            <label class="text-gray-400 mb-0 leading-none">
                                Email
                                <el-input v-model="form.email" placeholder="請輸入電子郵件" class="w-full" />
                            </label>
                        </el-form-item>
                        <!-- <el-checkbox-group v-model="form.marketing_notification">
                            <el-checkbox class="text-gray-300 leading-none" :label="0"
                                >我不想收到&nbsp;City&nbsp;Banana&nbsp;相關的行銷資訊</el-checkbox
                            >
                        </el-checkbox-group> -->
                    </div>
                    <el-form-item prop="government_cert">
                        <label class="text-gray-400 mb-0 block">身份證</label>
                        <div class="flex">
                            <el-select v-model="form.government_cert" class="rounded-tr-none rounded-none max-w-[120px]" placeholder="證件型態">
                                <el-option v-for="(item, index) in certOptions" :key="index" :label="item.label" :value="item.value"> </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="government_id">
                                <el-input v-model="form.government_id" placeholder="請輸入身份證" class="w-full" />
                            </el-form-item>
                        </div>
                    </el-form-item>
                </div>
                <div class="grid grid-cols-2 md:gap-4">
                    <div class="w-full md:col-span-1 col-span-2 mb-3">
                        <el-form-item prop="email">
                            <label class="text-gray-400 mb-0 leading-none">
                                聯絡電話
                                <el-input v-model="form.phone" placeholder="請輸入聯絡電話" class="w-full" />
                            </label>
                        </el-form-item>
                    </div>
                    <!-- <el-form-item prop="government_cert">
                        <label class="text-gray-400 mb-0 block">居住地址</label>
                        <div class="flex">
                            <el-select v-model="form.district" class="rounded-tr-none rounded-none max-w-[120px]" placeholder="地區">
                                <el-option v-for="(item, index) in areaOptions" :key="index" :label="item.name" :value="item.value"> </el-option>
                            </el-select>
                            <el-form-item class="flex-1" prop="address">
                                <el-input v-model="form.address"> </el-input>
                            </el-form-item>
                        </div>
                    </el-form-item> -->
                </div>

                <span class="block text-gray-400 text-sm mb-3 leading-none">客服註記</span>
                <slot name="provider-remarks"></slot>
                <el-form-item v-permission="['update']" class="w-full">
                    <div v-loading="sendNoteloading" class="flex items-end">
                        <label class="flex-1">
                            <el-input v-model="note" class="w-full" placeholder="請輸入註記內容"> </el-input>
                        </label>
                        <btn
                            color="border-red-500 border text-red-500"
                            size="md:text-sm text-xs px-2 py-2 rounded max-w-[150px]"
                            customClass="ml-2"
                            @onClick="sendNote(note)">
                            <!-- <i class="el-icon-plus text-lg"></i> -->
                            送出註記
                        </btn>
                        <!-- <button @click.prevent="sendNote(note)"
                                class="border-orange-600 border px-5 h-[40px] ml-2 rounded-lg text-orange-600 transition duration-200 hover:bg-orange-500 hover:text-white md:text-sm text-xs">
                            <i class="el-icon-plus text-lg"></i>
                            送出註記
                        </button> -->
                    </div>
                </el-form-item>
            </div>
        </div>
        <div class="flex justify-center mt-10">
            <btn @onClick="updateIntroducer"> 資料更新 </btn>
        </div>
    </el-form>
</template>

<script>
import { areas } from "@/langs/tw.json";
const defaultInput = {
    login: "",
    status: "",
    password: "",
    name: "",
    representative: "",
    email: "",
    government_id: "",
    government_cert: "",
    phone: "",
    district: "",
    address: "",
    marketing_notification: [],
};
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
export default {
    name: "Profile",
    components: {
        // Avatar
        Btn,
    },
    computed: {
        // 帳號狀態 options
        statusOptions() {
            return [
                {
                    label: "使用中",
                    value: 0,
                },
                {
                    label: "停用",
                    value: -1,
                },
            ];
        },
        // 身份認證類別選擇
        certOptions() {
            return [
                {
                    label: "身份證",
                    value: 0,
                },
                {
                    label: "護照",
                    value: 1,
                },
                {
                    label: "居留證",
                    value: 2,
                },
            ];
        },
        // 使用語系檔取得地區資料
        areaOptions() {
            return areas;
        },
    },
    data() {
        return {
            showPassword: false,
            // 預設大頭照
            sendNoteloading: false,
            note: "",
            loading: false,
            form: {
                ...defaultInput,
            },
            rules: {
                status: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                login: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        min: 6,
                        message: "需6字以上",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                representative: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    {
                        pattern: /8869[0-9]{8}$/,
                        message: `手機格式錯誤`,
                        trigger: "blur",
                    },
                ],
                address: [
                    {
                        required: false,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
                district: [
                    {
                        required: false,
                        message: "必填",
                        trigger: ["blur"],
                    },
                ],
                email: [
                    { required: true, message: "必填", trigger: "blur" },
                    {
                        type: "email",
                        message: "錯誤的電子郵件格式",
                        trigger: ["blur", "change"],
                    },
                    {
                        required: true,
                        validator: this.formEmailCheck,
                        trigger: "blur",
                    },
                ],
                government_id: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                    // 身份證字號驗證
                    // { required: true, pattern: /^[A-Z][12][0-9]{8}$/, message: "請輸入正確的身份證字號", trigger: "blur" }
                ],
                government_cert: [
                    {
                        required: true,
                        message: "必填",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        getBrokerData() {
            this.$api.GetSingleBrokerApi(this.id).then((res) => {
                this.form = { ...res.data };
                console.log(this.info);
            });
        },
        async updateIntroducer() {
            const result = await this.$refs["form"].validate();
            if (result) {
                this.$api
                    .UpdateSingleBrokerApi(this.id, this.form)
                    .then((res) => {
                        this.$message({
                            type: "success",
                            message: "更新成功",
                        });
                        this.form = { ...res.data.broker };
                    })
                    .catch((err) => {
                        this.$message({
                            type: "warning",
                            message: "更新失敗",
                        });
                        console.log(err);
                    });
            }
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getBrokerData();
    },
};
</script>
